import * as React from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';

export const CTAWrapper = styled.aside`
  text-align: center;
  margin: 4rem auto 6rem;
  max-width: 700px;
`;

export const CTASubtitle = styled.h3`
  font-size: 1rem;
  font-weight: 300;
  color: inherit;
`;

export const CTAFieldset = styled.fieldset`
  border: none;
  padding: 0;
  position: relative;
  margin: 0 auto;
  transition: ${theme.transitions.default};
  max-width: 500px;
  :disabled {
    filter: grayscale(1) brightness(0.6);
    cursor: wait;
    button,
    input {
      cursor: inherit;
    }
  }
`;

export const CheckButton = styled.button`
  color: inherit;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-weight: 300;
  font-size: 0.8em;
  transition: ${theme.transitions.fast};
  margin: 1em auto 0;
  /* border-radius: 0 0 50px 50px; */
  opacity: ${({ isActive }: { isActive: boolean }) => (isActive ? 1 : 0.7)};
  :focus {
    outline: 0;
  }
  span > svg {
    width: 1.5em;
    fill: currentColor;
    margin: 0 0.5em;
    vertical-align: middle;
  }
  path {
    transition: ${theme.transitions.fast};
  }
  @media screen and (max-width: 400px) {
    span > svg {
      display: none;
    }
  }
`;

export const ErrorMessage = styled.p`
  max-width: 350px;
  margin: 2rem auto 0;
  font-weight: 300;
  font-size: 0.8rem;
  text-align: left;
  display: flex;
  align-items: flex-start;
  svg {
    width: 2rem;
    margin-right: 0.5em;
  }
`;

export const Checkbox = styled.div`
  border: 2px solid white;
  border-radius: 0.4em;
  width: 1.5em;
  height: 1.5em;
  margin-right: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 70%;
    fill: currentColor;
    margin: 0;
    transition: ${theme.transitions.default};
    opacity: ${({ isChecked }: { isChecked: boolean }) => (isChecked ? 1 : 0)};
  }
`;

export const CTACheck: React.SFC<{ isChecked: boolean }> = ({ isChecked }) => (
  <Checkbox isChecked={isChecked}>
    <svg viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 5.909L1.508 4.43l3.77 3.693L13.57 0l1.507 1.477-9.8 9.602L0 5.909z" />
    </svg>
  </Checkbox>
);

export const AlertIcon = () => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="alert-icon__a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2.714a8.286 8.286 0 1 0 0 16.572 8.286 8.286 0 0 0 0-16.572zM.714 11C.714 5.32 5.32.714 11 .714 16.68.714 21.286 5.32 21.286 11c0 5.68-4.605 10.286-10.286 10.286C5.32 21.286.714 16.68.714 11z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6.286a1 1 0 0 1 1 1V11a1 1 0 0 1-2 0V7.286a1 1 0 0 1 1-1z"
      />
      <path d="M11.929 14.714a.929.929 0 1 1-1.858 0 .929.929 0 0 1 1.858 0z" />
    </mask>
    <path
      d="M3.714 11A7.286 7.286 0 0 1 11 3.714v-2A9.286 9.286 0 0 0 1.714 11h2zM11 18.286A7.286 7.286 0 0 1 3.714 11h-2A9.286 9.286 0 0 0 11 20.286v-2zM18.286 11A7.286 7.286 0 0 1 11 18.286v2A9.286 9.286 0 0 0 20.286 11h-2zM11 3.714A7.286 7.286 0 0 1 18.286 11h2A9.286 9.286 0 0 0 11 1.714v2zm0-4C4.767-.286-.286 4.767-.286 11h2A9.286 9.286 0 0 1 11 1.714v-2zM22.286 11C22.286 4.767 17.233-.286 11-.286v2A9.286 9.286 0 0 1 20.286 11h2zM11 22.286c6.233 0 11.286-5.053 11.286-11.286h-2A9.286 9.286 0 0 1 11 20.286v2zM-.286 11c0 6.233 5.053 11.286 11.286 11.286v-2A9.286 9.286 0 0 1 1.714 11h-2zM13 7.286a2 2 0 0 0-2-2v2h2zM13 11V7.286h-2V11h2zm-2 2a2 2 0 0 0 2-2h-2v2zm-2-2a2 2 0 0 0 2 2v-2H9zm0-3.714V11h2V7.286H9zm2-2a2 2 0 0 0-2 2h2v-2zm-.071 9.428c0-.04.032-.071.071-.071v2a1.929 1.929 0 0 0 1.929-1.929h-2zm.071-.071c.04 0 .072.032.072.071h-2c0 1.066.863 1.929 1.928 1.929v-2zm.072.071c0 .04-.032.072-.072.072v-2a1.929 1.929 0 0 0-1.928 1.928h2zm-.072.072a.071.071 0 0 1-.071-.072h2A1.929 1.929 0 0 0 11 12.786v2z"
      fill="#FB3640"
      mask="url(#alert-icon__a)"
    />
  </svg>
);
