import * as React from 'react';
import styled from 'styled-components';
import { getNeonShadow } from './Button';
import theme from '../styles/theme';
import { medias } from '../styles/helpers/mediaQueries';

interface IInputProps {
  value: string | number;
  name: string;
  placeholder: string;
  bg: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
}

export interface IInputState {
  focused: boolean;
}

const InputWrapper = styled.div`
  display: flex;
  position: relative;
`;

interface IStyledInputProps {
  color: string;
}

const StyledInput = styled.input`
  border-radius: 50px 0 0 50px;
  box-shadow: ${({ color }: IStyledInputProps) => getNeonShadow(color)};
  border: 2px solid ${({ color }: IStyledInputProps) => color};
  font-weight: 300;
  background: transparent;
  color: ${({ color }: IStyledInputProps) => color};
  display: block;
  padding: 0.35rem 0.75rem 0.35rem 1rem;
  flex: 1;
  font-size: 1rem;
  border-radius: 50px 0 0 50px;
  outline: none;
  :focus {
    color: white;
    ~ label {
      color: ${({ color }: IStyledInputProps) => color};
    }
  }
`;

interface IButtonStyleProps {
  color: string;
  bg: string;
}

const Button = styled.button`
  text-align: center;
  color: ${({ color }: IButtonStyleProps) => color};
  box-shadow: none;
  min-width: 0;
  border-radius: 0 50px 50px 0;
  padding: 0.5em 1.5em 0.5em 1em;
  line-height: 1;
  transition: ${theme.transitions.default};
  font-weight: 500;
  font-size: 0.9em;
  background: ${({ bg }: IButtonStyleProps) => bg};
  border: 2px solid ${({ bg }: IButtonStyleProps) => bg};
  /* Só pra consertar um bugzinho */
  margin-right: -3px;
  display: flex;
  justify-content: center;
  align-items: center;
  :focus,
  :hover {
    outline: none;
    filter: brightness(1.15);
    filter: saturate(1.15) brightness(1.15);
  }
  @media ${medias.phoneMax} {
    padding: 0.5em 0.75em 0.5em 0.5em;
    font-size: 0.8em;
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-55%);
  font-weight: 300;
  font-family: inherit;
  opacity: ${({ hasValue }: { hasValue: boolean }) => (hasValue ? 0 : 0.7)};
  transition: ${theme.transitions.default};
  cursor: text;
`;

export const EmailInput: React.SFC<IInputProps> = props => {
  const { name, onChange, value, placeholder, bg } = props;

  const inputProps = {
    type: 'email',
    value,
    name,
    id: name,
    required: true,
    onChange,
  };

  return (
    <InputWrapper>
      <StyledInput color={bg} {...inputProps} />
      <StyledLabel hasValue={value !== ''} htmlFor={name}>
        {placeholder}
      </StyledLabel>
      <Button
        color={bg === theme.colors.primary ? 'white' : theme.colors.darkPurple}
        bg={bg}
        type="submit"
      >
        Inscreva-se
      </Button>
    </InputWrapper>
  );
};

EmailInput.displayName = 'EmailInput';

export default EmailInput;
