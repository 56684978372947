interface ISubscibreToServices {
  inviteToMC: boolean;
  email: string;
}

interface IResJSON {
  beehiivRes?: 'ok' | string;
  slackRes?: 'ok' | string;
}

export const subscribeToServices = async ({
  inviteToMC,
  email,
}: ISubscibreToServices) => {
  const baseURL = '/.netlify/functions/signup';
  const finalURL = `${baseURL}?email=${email}&beehiivInvite=${inviteToMC}`;
  const res = await fetch(finalURL);
  if (res && res.type !== 'opaque') {
    const json: IResJSON = await res.json();
    return {
      mcErr: inviteToMC && json.beehiivRes !== 'ok',
    };
  } else {
    return {
      mcErr: inviteToMC,
    };
  }
};

export const subMessages = {
  errBoth:
    'Não conseguimos inscrever seu email... Talvez você já tenha uma conta?',
  successBoth:
    'Você agora vai receber **dois emails**: um para terminar seu cadastro na Newsletter, e outro para completar seu perfil no Slack, sem mistério ;) Enquanto isso, aproveita pra dar uma lida no nosso blog e ouvir nosso podcast!',
  successMc:
    'Você agora **vai receber um email** para terminar seu cadastro na Newsletter, sem mistério ;) Enquanto isso, aproveita pra dar uma lida no nosso blog e ouvir nosso podcast!',
  errMc:
    'Conseguimos te inscrever no Slack, mas, por algum motivo, houve um **erro em te inscrever para a Newsletter**... você pode tentar novamente abaixo ou talvez tentar com uma conta de email diferente?',
};
