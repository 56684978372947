import * as React from 'react';
import styled from 'styled-components';
import { textToParagraphs } from '../../../utils/strings';
import Button from '../../../components/Button';
import { IPodcastPreviewData } from '../../../components/Podcast/PodcastPreview';
import PodcastList from '../../../components/Podcast/PodcastList';
import theme from '../../../styles/theme';
import { medias } from '../../../styles/helpers/mediaQueries';

export interface IHomePodcastData {
  btnText: string;
  subtitle: string;
  title: string;
}

export interface IHomePodcastProps extends IHomePodcastData {
  podcasts: IPodcastPreviewData[];
}

const Wrapper = styled.div`
  max-width: ${theme.lgContainerWidth};
  margin: 5rem auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  flex: 0 0 100%;
  @media ${medias.largeMin} {
    flex: 1 0 280px;
    max-width: 500px;
  }
  margin-right: 1.5rem;
`;

const RightWrapper = styled.div`
  flex: 1 0 280px;
  max-width: 800px;
  @media ${medias.largeMax} {
    margin-bottom: 2rem;
    order: -1;
  }
  > div > div {
    margin: 0;
  }
`;

export const HomePodcast: React.SFC<IHomePodcastProps> = props => {
  return (
    <Wrapper>
      <ContentWrapper>
        <h2>{props.title}</h2>
        {textToParagraphs(props.subtitle)}
        <Button isSecondary={true} url="/podcast">
          {props.btnText}
        </Button>
      </ContentWrapper>
      <RightWrapper>
        <PodcastList podcasts={props.podcasts} />
      </RightWrapper>
    </Wrapper>
  );
};

HomePodcast.displayName = 'HomePodcast';

export default HomePodcast;
