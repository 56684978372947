import * as React from 'react';
import { navigate } from 'gatsby';

import EmailInput from '../EmailInput';
import { keyFromString } from '../../utils/strings';
import { subscribeToServices, subMessages } from '../../utils/subscribe';
import theme from '../../styles/theme';
import { toggleScroll } from '../../utils/dom';
import {
  CTAWrapper,
  CTASubtitle,
  CTAFieldset,
  ErrorMessage,
  AlertIcon,
} from './ctaStyles';

export interface ICTAProps {
  title?: string;
  subtitle?: string;
  btnBg?: string;
  inputName?: string;
}

export interface ICTAState {
  email: string;
  subscribeToBoth: boolean;
  isLoading: boolean;
  mcErr: boolean;
  // Campo pra bot
  pegaMalandro: string;
}

export class CTA extends React.Component<ICTAProps, ICTAState> {
  state = {
    email: '',
    subscribeToBoth: false,
    isLoading: false,
    mcErr: false,
    pegaMalandro: '',
  };

  handleSubmit = async (e: React.FormEvent) => {
    // Checar se tem email e evitar que o form recarregue a p[agina]
    const {
      state: { email, pegaMalandro },
    } = this;
    e.preventDefault();

    if (pegaMalandro !== '') {
      navigate('/404');
    }

    if (typeof email !== 'string' || email === '') {
      return;
    }

    // Mudar o estado do form para em carregamento
    this.setState({
      isLoading: true,
    });

    // Chamar a função lambda de cadastro
    const res = await subscribeToServices({ inviteToMC: true, email });

    // Com base nas respostas do servidor, vamos ou redirecionar o usuário
    // pra página de sucesso ou mostrar um erro. Esse redirecionamento também
    // pode carregar estado de erro porque se teve sucesso com algum mas não
    // com o outro, não queremos prover o mesmo form pra pessoa
    if (!res.mcErr) {
      // Passar pra página de obrigado informação sobre quais serviços
      // tentou se inscrever e o status dessa inscrição
      navigate('/obrigado');
      toggleScroll(true);
    } else {
      this.setState({
        isLoading: false,
        mcErr: res.mcErr,
      });
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: e.currentTarget.value,
    });
  };

  handleMalandro = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      pegaMalandro: e.currentTarget.value,
    });
  };

  toggleSubscribe = () => {
    this.setState(prev => ({
      subscribeToBoth: !prev.subscribeToBoth,
    }));
  };

  render() {
    const { email, isLoading, mcErr } = this.state;
    const {
      title,
      subtitle,
      inputName,
      btnBg = theme.colors.accent,
    } = this.props;

    // Testar qual mensagem de erro mostrar pro usuário
    const errorMessage = mcErr ? subMessages.errBoth : '';
    return (
      <CTAWrapper>
        {title ? <h2>{title}</h2> : null}
        {subtitle ? <CTASubtitle>{subtitle}</CTASubtitle> : null}
        <form onSubmit={this.handleSubmit}>
          <input
            hidden={true}
            onChange={this.handleMalandro}
            name="malandrinho"
            aria-hidden={true}
          />
          <CTAFieldset disabled={isLoading}>
            <EmailInput
              onChange={this.handleChange}
              name={
                inputName ||
                `${keyFromString(title || subtitle || 'ab')}-email-field`
              }
              value={email}
              placeholder="Digite seu melhor email"
              bg={btnBg}
            />
          </CTAFieldset>
        </form>
        {!isLoading && mcErr ? (
          <ErrorMessage>
            <AlertIcon />
            {errorMessage}
          </ErrorMessage>
        ) : null}
      </CTAWrapper>
    );
  }
}

export default CTA;
